<template>
  <div ref="communites" class="communites over-hid">
    <div class="contenter over-hid">
      <div class="table">
        <div class="table__header">
          <div class="table__title cell-settings__numbers" v-if="setIndex">
            <img src="/icons/table/hash.svg" class="table__img hash-img" />
          </div>

          <div class="table__title cell-settings__public" id="tt3">
            <search-user v-model:searchText="calcSearchText" class="table__search"></search-user>
          </div>

          <div class="table__title cell-settings__subscribes" @click.prevent="sort(1, $event)">
            Сообщества

            <img src="/icons/common/vector-arrow.svg" class="table__img" />
          </div>

          <div class="table__title cell-settings__coverage" @click.prevent="sort(2, $event)">
            Подписчики

            <img
              src="/icons/common/vector-arrow.svg"
              class="table__img img-show"
            />
          </div>

          <div class="table__title cell-settings__auditory" @click.prevent="sort(3, $event)">
            Охват

            <img src="/icons/common/vector-arrow.svg" class="table__img" />
          </div>

          <div class="table__title cell-settings__grow" @click.prevent="sort(4, $event)">
            Прирост

            <img src="/icons/common/vector-arrow.svg" class="table__img" />
          </div>

          <!-- <div
            v-if="checkLoginState"
            :style="{ opacity: 1 }"
            class="table__title cell-settings__eye"
          >
            <img src="/icons/table/eye.svg" class="table__icon-eye" />
          </div> -->
        </div>

        <pagination ref="pagination" :target="target" class="table__caption"></pagination>

        <circle-c v-if="getAnimation" class="animation-container__icon"></circle-c>

        <div v-else class="table__body">
          <div
            v-for="(item, index) in normalizeJSON"
            :key="item.id"
            :ref="setRef(item)"
            class="table__row"
            :class="{ select: isSelectClass(item.id) }"
          >
            <div class="table__title cell-settings__numbers" v-if="setIndex">
              {{ getPosition + index + 1 }}
            </div>

            <div class="table__cell cell-settings__public">
              <a :href="item.admin_link" target="_blank" class="link">
                <div class="table__image-size">
                  <img :src="item.photo_link" class="table__icon" />
                </div>

                <p>{{ `${item.first_name} ${item.second_name}` }}</p>
              </a>
            </div>

            <div class="table__cell cell-settings__subscribes">
              <a @click.ctrl="watchGroup(item, 'openInNewTab')" @click.exact="watchGroup(item)">
                <p class="text__bold">{{ item.groups_count }}</p>

                <p class="text__thin">{{ `/${item.open_stat || 0}` }}</p>
              </a>
            </div>

            <div class="table__cell cell-settings__coverage text__bold">
              <span v-if="checkParam(item.subs_count) !== ''">
                {{ item.subs_count === 0 ? "-" : item.subscrices }}
              </span>

              <img v-else src="/icons/table/lock.svg" class="image-size" />
            </div>

            <div class="table__cell cell-settings__auditory text__bold">
              <span v-if="checkParam(item.visitors_views) !== ''" class="text__main">
                {{ item.visitors_views === 0 ? "-" : item.coverage }}
              </span>

              <span class="text__sub" v-if="checkParam(item.visitors_views) !== ''">
                {{ item.visitors_views === 0 ? "" : item.covpercent }}
              </span>

              <img v-else src="/icons/table/lock.svg" class="image-size" />
            </div>

            <div
              class="
                table__cell
                cell-settings__grow
                text__bold text__color_green
                table__size_common
              "
              :class="{ text__color_red: +item.proc_grow < 0 || +item.grow_count < 0 }"
            >
              <arrow-icon :arrow="setArrow(item)" class="arrow"> </arrow-icon>

              <p class="text__main">{{ item.grocount }}</p>

              <!-- <sub class="text__sub">{{ `${calcNumberGro(item, true)}%` }}</sub> -->
            </div>

            <!-- <div
              v-if="checkLoginState"
              class="table__title cell-settings__eye"
              @click="toogleInfoPage"
            >
              <img src="/icons/table/eye.svg" class="table__icon-eye" />
            </div> -->
          </div>
        </div>

        <ScrollTop class="test"></ScrollTop>

        <infinity-scroll @intersect="intersected" id="tt" v-if="show" class="observe" />
      </div>
    </div>

    <aside class="settings" :class="{ show: getSettingsState, 'settings-height': getAnimation }">
      <div class="filters">
        <div class="static-info">Админов: {{ getGroupCount }}</div>

        <div class="auditory">
          <p class="auditory__title">Аудитория</p>

          <div class="close" @click="resetSlider('subcount')"></div>
        </div>

        <double-slider
          ref="subcount"
          sliderName="subcount"
          @update:resetInput="resetInput"
        ></double-slider>

        <div class="auditory">
          <p class="auditory__title">Прирост</p>

          <div class="close" @click="resetSlider('growcount')"></div>
        </div>

        <double-slider
          ref="growcount"
          sliderName="growcount"
          @update:resetInput="resetInput"
        ></double-slider>

        <div class="auditory">
          <p class="auditory__title">Охват</p>

          <div class="close" @click="resetSlider('reachcount')"></div>
        </div>

        <double-slider
          ref="reachcount"
          sliderName="reachcount"
          @update:resetInput="resetInput"
        ></double-slider>

        <div class="filters__radio-buttons">
          <p class="filters__title">Период</p>

          <radio-buttons
            :items="period"
            :groupName="'period'"
            class="filters__buttons"
            @updateItem="updateItem"
          ></radio-buttons>
        </div>

        <check-box
          :items="checkButtons"
          class="filters__checkbox"
          @emitState="updateCheckBox"
        ></check-box>
      </div>

      <hr class="line" />
    </aside>

    <teleport to="body">
      <transition name="fade">
        <group-statistic
          v-if="isOpenGroupInformation"
          @closeWindow="toogleInfoPage"
          class="group-statistic"
        ></group-statistic>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import RadioButtons from '@/components/atom/button/radio/RadioButtons.vue';
import CheckBox from '@/components/atom/button/check/CheckBox.vue';
import Pagination from '../../components/pagination/Pagination.vue';
import CircleC from '@/components/atom/loader/Circle.vue';
import ScrollTop from '@/components/atom/button/scroll/ScrollTop.vue';
import InfinityScroll from '@/components/pagination/InfinityScroll.vue';
import ArrowIcon from '@/components/atom/html-icon/arrow/ArrowIcon.vue';
import SearchUser from '@/components/common/BaseSearch.vue';
import GroupStatistic from '@/components/common/GroupStatistic.vue';
import DoubleSlider from '@/components/common/slider/AdminSliderDouble.vue';
import routing from '@/utils/routing';

// import SubscribesFilter from '@/components/common/filter/SliderFilter.vue';

let debounceCaller;

export default {
  components: {
    RadioButtons,
    CheckBox,
    Pagination,
    CircleC,
    InfinityScroll,
    ScrollTop,
    ArrowIcon,
    SearchUser,
    GroupStatistic,
    DoubleSlider,
    // SubscribesFilter,
  },
  data() {
    return {
      delbuf: '',
      show: false,
      searchText: '',
      rotate: 0,
      listShow: null,
      activeLink: null,
      selectedRows: [],
      options: {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      },
      target: null,
      observer: null,
      loadCount: false,

      period: [
        {
          id: 1,
          title: 'День',
          settingName: 'day',
          checked: true,
        },
        {
          id: 2,
          title: 'Неделя',
          settingName: 'week',
          checked: false,
        },
        {
          id: 3,
          title: 'Месяц',
          settingName: 'month',
          checked: false,
        },
      ],

      checkButtons: [
        {
          id: 1,
          title: 'Показывать позицию',
          name: 'indexes',
          isChecked: true,
        },
      ],

      // Hash map
      names: {
        Подписчики: 'sub',
        Сообщества: 'group',
        Прирост: 'grow',
        Охват: 'cov',
      },

      isOpenGroupInformation: false,
    };
  },
  computed: {
    ...mapGetters('vkRequests', [
      'getResultCommunities',
      'getGroupCount',
      'getCategory',
      'getAnimation',
      'getPosition',
    ]),
    ...mapGetters('vkConfiguration', ['getSettingsState']),
    ...mapGetters('adminRequest', ['checkLoginState']),

    takeResultCommunities() {
      this.getResultCommunities.forEach((item) => {
        if (item.index % 50 === 0) {
          this.selectedRows.push(item);
        }
      });
      return this.getResultCommunities;
    },

    calcCategorys() {
      return this.getCategory;
    },

    setIndex() {
      const isSet = this.checkButtons.find((item) => item.name === 'indexes');

      return isSet.isChecked;
    },

    calcSearchText: {
      get() {
        return this.searchText;
      },
      set(value) {
        debounceCaller();
        this.searchText = value;
      },
    },

    normalizeJSON() {
      const newJSON = this.getResultCommunities;
      newJSON.forEach((element, index) => {
        newJSON[index].subscrices = new Intl.NumberFormat('ru-RU').format(+element.subs_count);
        newJSON[index].coverage = new Intl.NumberFormat('ru-RU').format(+element.visitors_views);

        const cov = +element.visitors_views / +element.subs_count;
        const percent = (Number(cov.toFixed(2)) * 100).toFixed();
        newJSON[index].covpercent = `${percent}%`;

        newJSON[index].visitors = new Intl.NumberFormat('ru-RU').format(+element.vis_count);
        newJSON[index].grocount = new Intl.NumberFormat('ru-RU').format(+element.grow_count);
      });

      return newJSON;
    },
  },
  watch: {
    getAnimation(to) {
      if (to === true) {
        this.loadCount = false;
      }
    },
  },
  methods: {
    ...mapActions('vkRequests', [
      'requestCommunities',
      'requestByDate',
      'requestByType',
      'requestByStatus',
      'requestAllStaticParams',
      'requestCategory',
      'requestByCategory',
      'requestByVerification',
      'requestBySort',
      'requestBySearch',
      'requestAdministrators',
      'setAdminsId',
      'cancleRequest',
      'requestAdminStaticParams',
    ]),
    ...mapMutations('vkRequests', [
      'setCurrentDate',
      'setLoadPage',
      'setChangeAnotherPage',
      'resetJSON',
      'resetAllParams',
      'setSearchInput',
      'setFiltredParametr',
    ]),

    calcNumberGro(item, forSub = false) {
      // console.log('item.proc_grow', item.proc_grow, item);
      if (forSub) {
        if (item.proc_grow === 'none') {
          return 0.0;
        }
        return +item.proc_grow > 0 && +Number.parseFloat(item.proc_grow).toFixed(2) === 0
          ? `~${Number.parseFloat(item.proc_grow).toFixed(2)}`
          : `${Number.parseFloat(item.proc_grow).toFixed(2)}`;
      }

      return 0.0;

      // if (item.proc_grow === 'none') {
      //   return 0;
      // }
      // ~
      // return new Intl.NumberFormat('ru-RU').format(
      //   Math.round(item.proc_grow * item.subs_count * 0.01),
      // );
    },

    searchRequest() {
      // TODO need from back search by name admin
      this.resetJSON();

      this.setSearchInput(this.searchText);
      this.requestBySearch();
    },

    toogleInfoPage() {
      this.isOpenGroupInformation = !this.isOpenGroupInformation;
    },

    setClass(event) {
      switch (true) {
        case this.activeLink === null:
          this.activeLink = event.target;
          this.activeLink.classList.add('active');
          break;
        case this.activeLink !== event.target:
          this.activeLink.classList.remove('active');
          this.activeLink = event.target;
          this.activeLink.classList.add('active');
          break;
        case this.activeLink === event.target:
          this.activeLink.classList.remove('active');
          this.activeLink = null;
          break;
        default:
      }
      const resultStr = this.activeLink === null ? '' : this.activeLink.innerText;

      this.requestByCategory(resultStr);
      this.resetInput();
    },

    resetInput() {},

    setArrow(item) {
      if (item.proc_grow === 'none') {
        return 'arrow-up';
      }
      return +item.proc_grow >= 0 && +item.grow_count >= 0 ? 'arrow-up' : 'arrow-down';
    },

    isSelectClass(currentValue) {
      return currentValue % 50 === 0;
    },

    isSelectRef(currentValue) {
      return currentValue % 50 === 0 ? null : `ref${currentValue}`;
    },

    sort(index, event) {
      let sortBy = true;
      const element = event.target.querySelector('.table__img') ? event.target.querySelector('.table__img') : event.target;

      this.resetInput();
      switch (true) {
        case this.listShow === null:
          this.listShow = element;
          this.listShow.classList.add('img-show');
          break;
        case this.listShow !== element:
          this.listShow.classList.remove('img-show');
          this.listShow.style.transform = 'rotate(0deg)';
          this.listShow = element;
          this.listShow.classList.add('img-show');
          this.rotate = 0;
          // filtredParametr = this.names[event.target.innerText];
          break;
        default:
          this.rotate = 180 - this.rotate;
          sortBy = this.rotate === 0;

          this.listShow.style.transform = `rotate(${this.rotate}deg)`;
      }

      // this.listShow.classList.add('img-show');
      let currentValue = '';

      switch (+index) {
        case 1:
          currentValue = 'group';
          break;
        case 2:
          currentValue = 'sub';
          break;
        case 3:
          currentValue = 'cov';
          break;
        case 4:
          currentValue = 'grow';
          break;
        default:
      }
      // const filtredParametr = this.names[event.target.innerText];
      const filtredParametr = currentValue;
      const parametrs = {
        filtredParametr,
        sortBy,
      };

      this.requestBySort(parametrs);
    },
    updateItem(currentId, groupName) {
      this[groupName].forEach((element, index) => {
        if (element.id === currentId) {
          this[groupName][index].checked = true;

          this.callFromFilterRequest(groupName, element.settingName);
          return;
        }
        this[groupName][index].checked = false;
      });
    },
    updateCheckBox(button) {
      if (button.name === 'verification') {
        this.requestByVerification(button.isChecked);
        this.resetInput();
      }
    },

    callFromFilterRequest(groupName, settingName) {
      this.resetInput();
      switch (true) {
        case groupName === 'period':
          this.requestByDate(settingName);
          break;
        case groupName === 'type':
          this.requestByType(settingName);

          break;
        case groupName === 'status':
          this.requestByStatus(settingName);

          break;
        default:
      }
    },
    // dont use now
    setRef(currentItem) {
      const result = currentItem.id % 20;
      if (result === 0) {
        this.selectedRows.push(result);
      }
      return currentItem.id % 20 ? null : `ref${currentItem.id}`;
    },

    debounce(f, t) {
      return () => {
        const previousCall = this.lastCall;
        this.lastCall = Date.now();
        if (previousCall && this.lastCall - previousCall <= t) {
          clearTimeout(this.lastCallTimer);
        }
        this.lastCallTimer = setTimeout(() => f(), t);
      };
    },

    watchGroup(item, ctrlClick = '') {
      const newPath = routing.getRouteByAdminId(item.admin_id);

      if (ctrlClick === 'openInNewTab') {
        const routeData = this.$router.resolve(newPath);
        window.open(routeData.href, '_blank');
        return;
      }

      this.setSearchInput('');
      this.$router.push(newPath);
      // this.setAdminsId(item.admin_id);
    },

    async intersected() {
      if (!this.loadCount) {
        this.loadCount = true;
        return;
      }
      this.$refs.pagination.setNextButton();
    },

    checkParam(item) {
      return +item === 0.1 || +item === 0.2 || +item === 0.3 ? '' : item;
    },

    resetSlider(refName) {
      this.$refs[refName].resetParams();
    },
  },

  async mounted() {
    this.listShow = this.$refs.communites.querySelector('.img-show');

    debounceCaller = this.debounce(this.searchRequest, 1000);

    this.setLoadPage('requestAdministrators');

    this.setFiltredParametr('sub');
    await this.requestAdminStaticParams();

    await this.requestAdministrators();
    this.show = true;
    this.target = document.querySelector('.table__header');
  },
  beforeUnmount() {
    this.cancleRequest('tokenAdmin');

    this.resetAllParams();
  },
};
</script>

<style lang="scss" scoped>
.communites {
  display: flex;
  justify-content: center;
  align-items: stretch;

  position: relative;
  width: 100%;
}

.contenter {
  width: calc(100% - 350px);
}

.settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0 12px;

  // flex-basis: 20%;
  flex-basis: 350px;
  min-width: 350px;
  max-width: 350px;
  height: auto;

  background-color: #f7f7f7;
  border-left: 1px solid #d8dfea;
}

.table {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  // .table__caption
  &__caption {
    display: flex;
    justify-content: flex-end;
    height: 30px;

    margin: 0 5px 0 0;

    position: sticky;
    top: 0;
    width: fit-content;
    align-self: flex-end;
  }

  &__search {
    width: 90%;
    height: 35px;
    font-size: 18px;
    padding: 0 5px 5px;

    &::placeholder {
      font-size: 18px;
      line-height: 32px;
    }
  }

  &__image-size {
    width: 50px;
    height: 50px;

    margin: 0 5px 0 0;
  }

  &__icon {
    width: 50px;
    height: 50px;
  }

  &__icon-eye {
    width: 30px;
    height: 30px;
  }

  &__arrow {
    transform: rotate(-180deg);
  }

  &__img {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 0 5px 5px;
    opacity: 0;
  }

  // .table__header
  &__header {
    display: flex;
    background: #dee5eb;

    padding: 0 5px;
  }

  // .table__title
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 60px;
    cursor: pointer;

    color: #45688e;
  }

  // .table__body
  &__body {
    display: flex;
    flex-direction: column;

    border-top: 1px solid #dee5eb;
    // padding: 0 10px 0 0
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border-bottom: 1px solid #dee5eb;

    &:hover .cell-settings__eye {
      opacity: 1;
    }
  }

  // .table__cell
  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

    padding: 5px 0 5px;
    margin: 1px 0;

    background: #fff;

    &_medium {
      flex-basis: calc(100% / 6);
    }
    &_big {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      width: 30%;
    }
    &_small {
      width: 50px;
      justify-content: center;
      padding: 0;
    }

    &_start {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__size {
    &_common {
      min-width: 120px;
    }

    &_small {
      min-width: 50px;
      margin: 0 5px 0 0;
    }

    &_medium {
      min-width: 120px;
    }

    &_large {
    }
  }
}

.filters {
  width: 350px;

  // .filters__radio-buttons
  &__radio-buttons {
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    margin: 10px 0 0 0;
    width: 100%;
  }

  &__buttons {
  }

  &__title {
    color: #555;
    font-weight: 700;
    font-size: 18px;
  }

  // .filters__checkbox
  &__checkbox {
    margin: 10px 0 0 0;
  }
}

.static-info {
  color: #45688e;
  font-weight: 700;
  font-size: 18px;
  text-align: center;

  margin-top: 18px;
}

.auditory {
  display: flex;
  justify-content: flex-start;

  margin-top: 23px;
  height: 30px;
  align-items: center;
  position: relative;

  // .auditory__title
  &__title {
    color: #555;
    font-weight: 700;
    font-size: 18px;
  }
}
.categories {
  width: 100%;
  // .categories__menu
  &__menu {
  }

  // .categories__link
  &__link {
  }

  // .categories__catalogs
  &__catalogs {
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
  &::before,
  &::after {
    position: absolute;
    left: 0;
    content: " ";
    height: 33px;
    width: 6px;
    background-color: #333;
  }
  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.line {
  display: block;
  width: 100%;
  content: "";
  height: 0;
  display: block;
  border-top: 1px solid #d8dfea;
  margin: 10px 0 10px 0;
}

.catalog-folder {
  width: 100%;
}
.link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.img-show {
  opacity: 1;
}

.display-panel {
  display: flex;
  justify-content: flex-start;

  align-items: stretch;
  height: 100%;
}
.panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  // .panel__image
  &_align-top {
    justify-content: flex-start;
  }
  &__image {
    width: 20px;
    height: 20px;
  }

  // .panel__text
  &__text {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  // .panel__info
  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 10px;
  }

  // .panel__tags
  &__tags {
    font-size: 10px;
    content: "";
  }
}

.text {
  &__size {
    font-size: 18px;
  }
  &__bold {
    font-weight: 700;
  }
  &__thin {
    font-weight: 300;
  }
  &__decor {
    &:hover {
      text-decoration: underline;
    }
  }
  &__color {
    &_link {
      color: #2b587a;
      cursor: pointer;
    }
    &_green {
      color: #7ea65a;
    }
    &_red {
      color: #d44f30;
    }
  }
  // TODO rename this
  &__main {
    margin: 0 8px 0 0;
  }

  &__sub {
    // align-self: flex-end;
    font-size: 13px;
  }
}

.animation-container {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  margin: 0 0 10px 0;

  height: 40px;
  width: 100%;

  background: #383f48;
  border: 1px solid #383f48;
  box-sizing: border-box;
  border-radius: 10px;

  &__icon {
    position: relative;
    width: 125px;
    height: 125px;
    margin: 50px auto 0;
  }

  &__hidden {
    display: none;
  }
}
.test {
  position: fixed;
  bottom: 27px;
  right: calc(41vw - 23%);
  z-index: 15;
}

.arrow {
  margin: 0 5px 5px 0;
}

.observe {
  content: "";
  display: flex;
  height: 81px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.image-size {
  width: 15px;
  height: 15px;
  // margin: 0 0 0 10%;
}
.hash-img {
  opacity: 1;
}

.group-statistic {
  position: absolute;

  top: 0;
  left: 0;

  z-index: 100;
}

.cell-settings {
  &__numbers {
    min-width: 50px;
    width: 50px;
    margin: 0 15px 0 0;
  }

  &__public {
    min-width: 240px;
    width: 39%;

    justify-content: flex-start;

    @media (max-width: 1500px) {
      width: 35%;
    }
  }

  &__coverage {
    min-width: 120px;
    width: calc((100% - 39% - 50px) / 4);
    cursor: pointer;
  }

  &__subscribes {
    min-width: 120px;
    width: calc((100% - 39% - 50px) / 4);
    cursor: default;
  }

  &__auditory {
    min-width: 120px;
    width: calc((100% - 39% - 50px) / 4);
    cursor: default;
  }

  &__grow {
    min-width: 120px;
    width: calc((100% - 39% - 50px) / 4);
    cursor: default;
    justify-content: center;
  }

  &__eye {
    min-width: 60px;
    width: var(--cell-eye);
    opacity: 1;
  }
}

@media (max-width: 1300px) {
  .settings {
    position: absolute;
    top: 90px;
    right: 3px;
    visibility: hidden;
    width: 50%;
    max-width: 350px;
    height: 100%;
  }

  .contenter {
    width: 100%;
  }

  .show {
    visibility: visible;
  }

  .img-show {
    opacity: 1;
  }
}

@media (max-width: 1170px) {
  .settings {
    padding: 0px 0px 0 5px;
    width: 50%;
    max-width: 350px;
  }

  .filters {
    // width: 152px;
    width: 100%;
  }

  .static-info {
    text-align: start;
  }

  .table {
    // .table__cell
    &__cell {
      display: flex;
      // justify-content: center;
      align-items: center;
      height: 60px;

      padding: 5px 0 5px;
      margin: 1px 0;

      // border-top: 1px solid cornflowerblue;
      // border-bottom: 1px solid cornflowerblue;

      background: #fff;

      &_medium {
        flex-basis: calc(100% / 6);
      }
      &_big {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        width: 30%;
      }
      &_small {
        width: 50px;
        justify-content: center;
        padding: 0;
      }
      &_start {
        display: flex;
        justify-content: flex-start;
      }
      // width: 100%;
    }

    &__size {
      &_common {
        min-width: 120px;
      }

      &_small {
        min-width: 50px;
      }

      &_medium {
        min-width: 190px;
        width: 190px;
      }

      &_large {
      }
    }
  }
}

@media (max-width: 1100px) {
  .settings {
    position: absolute;
    top: 90px;
    right: 3px;
    visibility: hidden;
    width: 50%;
    max-width: 350px;
    height: 100%;
  }

  .show {
    visibility: visible;
  }

  .img-show {
    opacity: 1;
  }
}

@media (hover: hover) {
  .table {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    // .table__caption

    &__row {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      border-bottom: 1px solid #dee5eb;

      padding: 0 8px;

      &:hover .cell-settings__eye {
        opacity: 1;
      }
    }
  }

  .cell-settings {
    &__numbers {
      min-width: 50px;
      width: 50px;
      margin: 0 15px 0 0;
    }

    &__public {
      min-width: 250px;
      width: 40%;
      justify-content: flex-start;

      @media (max-width: 1500px) {
        width: 35%;
      }
    }

    &__coverage {
      min-width: 120px;
      width: calc((100% - 40% - 50px) / 4);
      cursor: default;
    }

    &__subscribes {
      min-width: 120px;
      width: calc((100% - 40% - 50px) / 4);
      cursor: pointer;
    }

    &__auditory {
      min-width: 120px;
      width: calc((100% - 40% - 50px) / 4);
      cursor: default;
    }

    &__grow {
      min-width: 120px;
      width: calc((100% - 40% - 50px) / 4);
      cursor: default;
      justify-content: center;
    }

    &__eye {
      min-width: 60px;
      width: var(--cell-eye);
      opacity: 0;
    }
  }
}
.over-hid {
  // overflow: hidden;
}

.settings-height {
  height: 100vh;
}

.group-statistic {
  position: absolute;

  top: 0;
  left: 0;

  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
