import router from '@/router';

export default {
  /**
   * @param { String }
   * @returns { String }
   */
  calcNewPath(route = {}) {
    const { _value: value } = router.currentRoute;

    const newPath = {
      name: '',
      params: {},
    };
    const pathName = value.name;

    let basePageName = this.getPageName(pathName);

    const { nameId, catalogsId } = value.params;
    const { param, name } = route;

    // console.log('gsderewrw', param, name, userId, nameId, catalogsId);

    switch (true) {
      case name === 'nameId' && !!param:
        basePageName = 'communities';
        // if (userId) {
        //   basePageName = `user-${basePageName}`;
        //   newPath.params.userId = userId;
        // }

        basePageName = `${basePageName}-admin`;
        newPath.params.nameId = param;

        if (catalogsId) {
          basePageName = `${basePageName}-catalog`;
          newPath.params.catalogsId = catalogsId;
        }
        break;
      case name === 'catalogsId' && !!param:
        // if (userId) {
        //   basePageName = `user-${basePageName}`;
        //   newPath.params.userId = userId;
        // }
        if (nameId) {
          basePageName = `${basePageName}-admin`;
          newPath.params.nameId = nameId;
        }

        basePageName = `${basePageName}-catalog`;
        newPath.params.catalogsId = param;
        break;
      case name === 'catalogsId':
        // if (userId) {
        //   basePageName = `user-${basePageName}`;
        //   newPath.params.userId = userId;
        // }
        if (nameId) {
          basePageName = `${basePageName}-admin`;
          newPath.params.nameId = nameId;
        }
        break;
      default:
    }

    newPath.name = basePageName;
    // console.log('checkss routing', newPath, name === 'catalogsId', !!param);
    return newPath;
  },

  getRouteByAdminId(newNameId) {
    // console.log('getRouteByAdminId calls');
    return this.calcNewPath({ param: newNameId, name: 'nameId' });
  },
  getRouteByCatalog(newCatalogId) {
    // console.log('getRouteByCatalog calls');
    return this.calcNewPath({ param: newCatalogId, name: 'catalogsId' });
  },

  getPageName(currentPage) {
    let baseName = '';
    switch (true) {
      case currentPage.includes('communities'):
        baseName = 'communities';
        break;
      case currentPage.includes('changes'):
        baseName = 'changes';
        break;
      case currentPage.includes('administrator'):
        baseName = 'administrator';
        break;
      case currentPage.includes('public'):
        baseName = 'public';
        break;
      default:
    }
    return baseName;
  },
};
