<template>
  <div v-if="sliderName === 'subcount'" class="content" ref="content">
    <div
      id="slider-subcount"
      :se-min="minParams"
      se-step="1"
      :se-min-value="minParams"
      :se-max-value="maxParams"
      :se-max="maxParams"
      class="slider"
      @click="$emit('update:resetInput')"
    >
      <div class="slider-touch-left">
        <span class="spanl span1"></span>
      </div>

      <div class="slider-touch-right">
        <span class="spanr span1"></span>
      </div>

      <div class="slider-line">
        <span class="span2"></span>
      </div>
    </div>

    <div class="result">
      <input type="text" class="input" v-model="minParams" @input="setParams" />

      <p class="result__text">-</p>

      <input type="text" class="input" v-model="maxParams" @input="setParams" />
    </div>
  </div>

  <div v-if="sliderName === 'growcount'" class="content" ref="content">
    <div
      id="slider-growcount"
      :se-min="minParams"
      se-step="1"
      :se-min-value="minParams"
      :se-max-value="maxParams"
      :se-max="maxParams"
      class="slider"
      @click="$emit('update:resetInput')"
    >
      <div class="slider-touch-left">
        <span class="spanl span1"></span>
      </div>

      <div class="slider-touch-right">
        <span class="spanr span1"></span>
      </div>

      <div class="slider-line">
        <span class="span2"></span>
      </div>
    </div>

    <div class="result">
      <input type="text" class="input" v-model="minParams" @input="setParams" />

      <p class="result__text">-</p>

      <input type="text" class="input" v-model="maxParams" @input="setParams" />
    </div>
  </div>

  <div v-if="sliderName === 'reachcount'" class="content" ref="content">
    <div
      id="slider-reachcount"
      :se-min="minParams"
      se-step="1"
      :se-min-value="minParams"
      :se-max-value="maxParams"
      :se-max="maxParams"
      class="slider"
      @click="$emit('update:resetInput')"
    >
      <div class="slider-touch-left">
        <span class="spanl span1"></span>
      </div>

      <div class="slider-touch-right">
        <span class="spanr span1"></span>
      </div>

      <div class="slider-line">
        <span class="span2"></span>
      </div>
    </div>

    <div class="result">
      <input type="text" class="input" v-model="minParams" @input="setParams" />

      <p class="result__text">-</p>

      <input type="text" class="input" v-model="maxParams" @input="setParams" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReachSlider from '@/assets/js/slider/reachSlider';
import ChSlider from '@/assets/js/slider/chSlider';
import AuditSlider from '@/assets/js/slider/auditSlider';

// let this.newSlidet = null;
// let callDebounce = null;

export default {
  emits: ['update:resetInput'],
  props: {
    getCounts: {
      type: Object,
      required: true,
    },

    sliderName: {
      type: String,
      required: true,
      // subcount, growcount, reachcount
    },
  },
  computed: {
    ...mapGetters('vkRequests', ['getAdminStaticParams', 'calcForWatch']),
  },
  watch: {
    getAdminStaticParams: {
      handler() {
        if (this.getAdminStaticParams.newRequest) {
          this.newSlidet.reset();

          this.minParams = this.getAdminStaticParams[this.sliderName].countFrom;
          this.maxParams = this.getAdminStaticParams[this.sliderName].countTo;

          this.newSlidet.setMin(Number.parseInt(this.minParams, 10));
          this.newSlidet.setMax(Number.parseInt(this.maxParams, 10));
          return;
        }

        this.minParams = Number.parseInt(this.getAdminStaticParams[this.sliderName].countFrom, 10);
        this.maxParams = Number.parseInt(this.getAdminStaticParams[this.sliderName].countTo, 10);

        this.newSlidet.setMin(
          Number.parseInt(this.getAdminStaticParams[this.sliderName].diapazoneFrom, 10),
        );
        this.newSlidet.setMax(
          Number.parseInt(this.getAdminStaticParams[this.sliderName].diapazoneTo, 10),
        );
      },
      deep: true,
      // it used if we takes new diapazone of auditory
    },
  },

  data() {
    return {
      newSlidet: null,
      minParams: 0,
      maxParams: 1000,
      saveName: '',

      lastCall: null,
      lastCallTimer: null,

      isChanged: {
        inputFrom: true,
        inputTo: true,
      },

      width: 0,

      callDebounce: null,
    };
  },

  methods: {
    ...mapActions('vkRequests', ['setAndRequestAdminsParams']),

    // TODO use vuex for reset, now reset used close button mb del
    resetParams() {
      this.minParams = Number.parseInt(
        this.getAdminStaticParams[this.sliderName].diapazoneFrom,
        10,
      );
      this.maxParams = Number.parseInt(this.getAdminStaticParams[this.sliderName].diapazoneTo, 10);

      this.auditoryRequest();
      this.updateSlider();
    },

    async setParams() {
      this.minParams = this.minParams === '' ? 0 : Number.parseInt(this.minParams, 10);
      this.maxParams = this.maxParams === '' ? 0 : Number.parseInt(this.maxParams, 10);

      await this.setValidMinMaxParams(this.minParams, this.maxParams);
      if (!Number.isInteger(this.minParams) && !Number.isInteger(this.maxParams)) {
        // TODO here mb need add alert window for users
        return;
      }

      this.updateSlider();
      this.callDebounce();
    },

    updateSlider() {
      // TODO make check methods
      this.newSlidet.setMinValue(Number.parseInt(this.minParams, 10));
      this.newSlidet.setMaxValue(Number.parseInt(this.maxParams, 10));
    },

    // for slider callback
    updateValues(min, max) {
      this.minParams = min;
      this.maxParams = max;
    },
    updateValuesRequest(min, max) {
      this.minParams = min;
      this.maxParams = max;

      this.auditoryRequest();
      // this.$emit('requestParams');
    },

    auditoryRequest() {
      const newAuditory = {
        name: this.sliderName,
        countFrom: this.minParams,
        countTo: this.maxParams,
      };

      this.setAndRequestAdminsParams(newAuditory);
      this.$emit('update:resetInput');
    },

    debounce(f, t) {
      return () => {
        const previousCall = this.lastCall;
        this.lastCall = Date.now();

        if (previousCall && this.lastCall - previousCall <= t) {
          clearTimeout(this.lastCallTimer);
        }

        this.lastCallTimer = setTimeout(() => f(), t);
      };
    },

    reCalculateSlider() {
      this.newSlidet.reset();
    },

    resizge() {
      if (this.width !== document.documentElement.clientWidth) {
        this.width = document.documentElement.clientWidth;

        this.reCalculateSlider();
      }
    },

    setValidMinMaxParams(min, max) {
      const { diapazoneFrom, diapazoneTo } = this.getAdminStaticParams[this.sliderName];
      this.minParams = min < diapazoneFrom ? diapazoneFrom : min;
      this.maxParams = max > diapazoneTo ? diapazoneTo : max;
    },
  },

  mounted() {
    window.addEventListener('resize', this.resizge);

    this.callDebounce = this.debounce(this.auditoryRequest, 1000);

    switch (this.sliderName) {
      case 'subcount':
        this.newSlidet = new AuditSlider('slider-subcount');
        break;
      case 'growcount':
        this.newSlidet = new ChSlider('slider-growcount');
        break;
      case 'reachcount':
        this.newSlidet = new ReachSlider('slider-reachcount');
        break;
      default:
    }

    // TODO first setting slider mb need replace for calcForWatch

    this.minParams = this.getAdminStaticParams[this.sliderName].countFrom;
    this.maxParams = this.getAdminStaticParams[this.sliderName].countTo;

    this.newSlidet.setMin(Number.parseInt(this.minParams, 10));
    this.newSlidet.setMax(Number.parseInt(this.maxParams, 10));

    // set listener for slider
    this.newSlidet.onChange = (min, max) => this.updateValues(min, max);
    this.newSlidet.didChanged = (min, max) => this.updateValuesRequest(min, max);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizge);

    this.newSlidet = null;
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
}

.slider {
  display: block;
  position: relative;

  height: 36px;
  width: 100%;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.slider-touch-left,
.slider-touch-right {
  display: block;

  position: absolute;

  height: 36px;
  width: 36px;

  padding: 6px;

  z-index: 2;
}

.slider-line {
  position: absolute;
  left: 18px;
  top: 16px;

  width: calc(100% - 36px);
  height: 10px;
  border-radius: 4px;

  z-index: 0;
  overflow: hidden;

  background: #d0d9e3;
  border: 1px solid #a2b3c6;
}

.span2 {
  display: block;

  height: 100%;
  width: 0%;

  border: 1px solid #a2b3c6 !important;
  background: #a2b3c6;
}

.span1 {
  display: block;

  width: 100%;
  height: 100%;

  border: 1px solid #a2b3c6 !important;
  background: #fff;
  border-radius: 50%;
}

.result {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 20px 0 0 0;

  &__text {
    margin: 0 15px;
  }
}

.input {
  display: flex;

  width: 100%;
  height: 25px;

  margin: 0 10px;

  align-items: center;
  font-size: 16px;
  text-align: center;
  opacity: 0.5;

  &:focus {
    opacity: 1;
  }
}

@media (max-width: 1100px) {
  .input {
    margin: 0 3px;
  }
  .result {
    &__text {
      margin: 0 0 0 0;
    }
  }
}
</style>
